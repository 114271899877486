import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ClientBodyModel, ClientSearchResponseModel, GetLastTransactionModel } from '../../models/client-search/client-search.model';
import { ClaveSuraGenerada, ClientBalanceModel, ClientEmail, EnviarClaveCliente, LastLoginModel, EnviarClaveTransaccionalCliente, EnviarClaveClienteJuridico } from '../../models/client-summary/client-summary.model';
import { AccionInversionModel } from '../../models/client-summary/accion-inversion.model';
import { ClientExecutiveModel } from '../../models/client-summary/client-executive.model';
import { RequestHandlerService } from '../../../core/services/request-handler/request-handler.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environment';
import { ClientSignedMandate, ClientSiteRequestModel } from '../../models/client-summary/client-data.model';
import { ProfitabilityModel } from '../../models/client-summary/profitability.model';
import { TransactionsModel } from '../../models/client-summary/transactions.model';
import { LocalStorageKeys } from '../../../shared/services/executive/executive.service';
import { ReCaptchaService } from 'src/app/shared/services/re-captcha/re-captcha.service';
import { PerfilInvestor, QuestionsProfile, ResponseQuestionsProfile } from '../../models/client-summary/change-profile.model';
import { AddressDomain, LastContactabilityUpdateObs, LastContactabilityUpdateResponse, ProfileSummaryData, ProfileSummaryParams, UpdateContactabilityDTO, UpdateContactabilityResponse } from '../../models/client-summary/profile-summary.model';
import { ClientPensionModel } from '../../models/client-summary/client-pension.model';
import * as moment from 'moment';
import { InputLog } from '../../models/log/InputLog';
import { Status } from '../../models/log/status';
import { TracesService } from 'src/app/core/services/traces/traces.service';
import { ConfigIndexLogStash } from '../../models/log/models';
import { LastSessionModel } from 'src/app/shared/models';
import { ClientFilterDataService } from 'src/app/shared/services/client-filter-data/client-filter-data.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class ClientRequestService {
    public clientData = new BehaviorSubject<ClientSearchResponseModel>(null);
    public signMandate = new BehaviorSubject<ClientSignedMandate>({} as ClientSignedMandate);
    public clientBalances = new BehaviorSubject<ClientBalanceModel[]>([]);
    public clientAccionInversion = new BehaviorSubject<AccionInversionModel>({} as AccionInversionModel);
    public clientLastSesion = new BehaviorSubject<LastLoginModel>({} as LastLoginModel);
    public clientExecutive = new BehaviorSubject<ClientExecutiveModel>({} as ClientExecutiveModel);

    public personInfo = new BehaviorSubject<any>({});
    public companyInfo = new BehaviorSubject<any>(null);
    public showNoHealth = new BehaviorSubject<boolean>(false);
    public profitability = new BehaviorSubject<ProfitabilityModel>({} as ProfitabilityModel);
    public documents = new BehaviorSubject<any>(null);
    public statusPassword = new BehaviorSubject<any>(null);
    public pensionData = new BehaviorSubject<any>(null);
    public lastSesion = new BehaviorSubject<any>(null);
    public contactabilityData = new BehaviorSubject<ProfileSummaryData>({} as ProfileSummaryData);
    public lastContactabilityUpdate = new BehaviorSubject<LastContactabilityUpdateObs>({} as LastContactabilityUpdateObs);

    public claveSecreta = new EventEmitter<any>();

    constructor(
        private requestHandler: RequestHandlerService,
        private router: Router,
        private activateRoute: ActivatedRoute,
        private reCaptchaService: ReCaptchaService,
        private tracesService: TracesService,
        private clientFilterDataService: ClientFilterDataService,
        private http: HttpClient
    ) { }

    public async loadData(data: ClientBodyModel) {
        const logtemp: Partial<InputLog> & Pick<InputLog, "Categoria" | "Metodo" | "Operacion" | "status"> = {
            Categoria: "Resumen Cliente",
            Operacion: "Resumen Cliente",
            Metodo: "loadData(data: ClientBodyModel)",
            eventoLog: "Carga Inicial",
            status: Status.OK,
            Secuencia: 0,
            EntradaLog: {
                accion: 'Carga Inicial cargado',
            },
        };
        try {
            const dataCliente = await this.clientFilterDataService.getDatosCliente(this.clientData.value);
            // const fullname = this.clientData.value.NombreCliente.split(' ').filter((x) => x !== '');
            // const name = fullname[0]?.toLocaleLowerCase().charAt(0);
            // const lastName = fullname[2]?.toLocaleLowerCase().charAt(0);

            // cambiar url para forzar error
            const allReqs = [
                { req: this.getBalances(data), next: this.clientBalances },
                { req: this.getSignedMandate(data), next: this.signMandate },
                { req: this.getAccionInversion(data.rut, dataCliente.nombre, dataCliente.apellido), next: this.clientAccionInversion },
                { req: this.getExecutiveClient(data), next: this.clientExecutive },
                { req: this.getPersonInfo(data), next: this.personInfo },
                { req: this.getCompanyInfo(data), next: this.companyInfo },
                { req: this.getDocuments(data), next: this.documents },
                { req: this.getStatusPassword(data), next: this.statusPassword },
                { req: this.getPension(data.rut), next: this.pensionData },
                { req: this.getSetUltimaSesionCliente('/getUltimaSesionCliente', data.rut, 'CLI'), next: this.lastSesion },
            ];
            const errosLogs: any[] = [];
            const allData = await Promise.allSettled(allReqs.map((r) => r.req));
            sessionStorage.removeItem("clienteBalances");
            allData.forEach((res, index) => {
                const { next } = allReqs[index];
                if (res.status === 'fulfilled') {
                    const resData = allData[index] as PromiseFulfilledResult<unknown>;
                    next.next(resData.value);
                } else if (res.status === 'rejected') {
                    const resData = allData[index] as PromiseRejectedResult;
                    next.next(null);
                    errosLogs.push({
                        serviceError: resData.reason.statusText
                    })
                }
            });

            if (allData.find(item => item.status === 'fulfilled')) {
                const perfil: any = allData[3];
                const ficha = perfil?.value?.poseeFichacliente?.dataOptimus?.SocioNegocio[0];
                const tipoPersona = this.tipoPersona(ficha);
                sessionStorage.setItem('tipoPersona', tipoPersona);
            }

            if (allData.find(item => item.status === 'rejected')) {
                logtemp.EntradaLog.accion = 'Carga Inicial error: existen servicios que no responden.';
                logtemp.eventoLog = 'Carga Inicial cargado : existen servicios que no responden.';
                logtemp.EntradaLog.data = errosLogs;
            }
            this.logData(
                logtemp,
                errosLogs.length > 0 ? "log_error" : "log_negocio",
            );
        } catch (error) {
            logtemp.EntradaLog.accion = 'Carga Inicial error: excepcion inesperada.';
            logtemp.status = Status.NOK;
            this.logData(
                logtemp,
                "log_error",
            );
            return error;
        }
    }

    public async getUltimaSesionCliente(rut: string) {
        const idFirestoreSesion = sessionStorage.getItem('idFirestoreSesion');
        const tipoSesion = 'ASE';
        this.getSetUltimaSesionCliente('/getUltimaSesionCliente', rut, tipoSesion, idFirestoreSesion)
            .then((res: any[]) => {
                if (res?.length) {
                    const fecha: string = res[0].fecha;
                    const lastLoginMoment = moment(fecha).utc();
                    const lastSessionStr = `Última sesión ${lastLoginMoment.clone().format('DD/MM/YYYY')} - ${lastLoginMoment.clone().format('HH:mm')} hrs.`;
                    sessionStorage.setItem('last_session', lastSessionStr);
                    const idFirestoreSesion: string = res[0].id;
                    sessionStorage.setItem('idFirestoreSesion', idFirestoreSesion);
                } else {
                    sessionStorage.setItem('last_session', '-');
                }
                this.getSetUltimaSesionCliente('/setUltimaSesionCliente', rut, tipoSesion, idFirestoreSesion);
            })
            .catch((error) => {
                console.error('error', error);
            });
    }

    getClientLoadData(data: ClientBodyModel): Promise<[ClientSignedMandate, ClientSearchResponseModel]> {
        return Promise.all([this.getSignedMandate(data), this.searchClient(data)]).then(([signedMandate, searchClient]) => {
            this.signMandate.next(signedMandate);
            this.clientData.next(searchClient);
            return [signedMandate, searchClient];
        });
    }

    async searchClient(data: ClientBodyModel) {
        const url = `${environment.ENDPOINTS.BFF.BFF_CUSTOMERS_ASESORES}/client-data/guc-customer/${data.rut}`;
        return this.requestHandler.doGet<ClientSearchResponseModel>(url).then(async (clientSearchResponseModel: ClientSearchResponseModel) => {
            const regexSpace = /\s+/g;
            let datosCliente = await this.completarDatosCliente(data.rut, clientSearchResponseModel);
            datosCliente.NombreCliente = datosCliente.NombreCliente.trim().replace(regexSpace, ' ');
            this.clientData.next(datosCliente);
            sessionStorage.setItem('clientInfoLog', JSON.stringify({
                nombre: datosCliente.NombreCliente,
                rut: `${datosCliente.RutCliente}-${datosCliente.DigitoVerificador}`
            }))
            return datosCliente;
        });
    }

    async searchClientCompliance(data: ClientBodyModel) {
        try {
            const url = `${environment.ENDPOINTS.BFF.BFF_CUSTOMERS_ASESORES}/client-data/guc-customer-compliance/${data.rut}`;
            return this.requestHandler.doGet<ClientSearchResponseModel>(url).then(async (clientSearchResponseModel: ClientSearchResponseModel) => {
                return clientSearchResponseModel;
            });
        } catch (error) {
            return error;
        }
    }

    async getPension(rut: string) {
        const url = `${environment.ENDPOINTS.BFF.BFF_CUSTOMERS_ASESORES}/client-data/monto-pension?rut=${rut}`;
        return this.requestHandler.doGet<ClientPensionModel>(url);
    }

    getSignedMandate(data: ClientBodyModel) {
        const url = `${environment.ENDPOINTS.BFF.BFF_CLIENTUSERS}/mandate`;
        return this.requestHandler
            .doPost<ClientSignedMandate>(url, {
                ...data,
            })
            .then((clientSignedMandate: ClientSignedMandate) => {
                this.signMandate.next(clientSignedMandate);
                return clientSignedMandate;
            });
    }

    getAccionInversion(rut: string, name: string, lastName: string) {
        const url = `${environment.ENDPOINTS.BFF.BFF_CLIENTUSERS}/accion-inversion`;
        lastName = lastName === undefined ? ' ' : lastName;
        return this.requestHandler.doPost<AccionInversionModel>(url, {
            rut,
            name,
            lastName,
            businessLine: 'SV',
        });
    }

    getAccionInversionNoCliente(rut: string, name: string, lastName: string) {
        const url = `${environment.ENDPOINTS.BFF.BFF_CLIENTUSERS}/compliance-no-cliente`;
        lastName = lastName === undefined ? ' ' : lastName;
        return this.requestHandler.doPost<any>(url, {
            rut,
            name,
            lastName,
        });
    }


    getProfitability(data: ClientBodyModel) {
        // const url = `${environment.ENDPOINTS.BFF.BFF_CUSTOMERS_ASESORES}/profitability/${data.rut}`;
        // return this.requestHandler.doGet<ProfitabilityModel>(url);
        const url = `${environment.ENDPOINTS.BFF.BFF_FINANCIAL_REPORT}/getRentabilidadPorPeriodo`;
        return this.requestHandler.doPost<ProfitabilityModel>(url, data);
    }


    async getGananciaPerdida(data: ClientBodyModel) {
        try{

            const url = `${environment.ENDPOINTS.BFF.BFF_TRANSVERSAL}/ganancia-perdida/obtener-configuracion?rut=${data.rut}`;
            const res:any = await this.http.get(url).toPromise();
            return res.data?.datosGananciaPerdida ?? {}
        }catch(err){
            return {}
        }
    }


    getDocuments(data: ClientBodyModel) {
        const url = `${environment.ENDPOINTS.BFF.BFF_CUSTOMERS_ASESORES}/client-data/documents/${data.rut}`;
        return this.requestHandler.doGet<any>(url);
    }

    getPersonInfo(data: ClientBodyModel) {
        if(environment.name === 'QA'){
            return null;
        }
        const url = `${environment.ENDPOINTS.BFF.BFF_CUSTOMERS_ASESORES}/client-data/person-info/${data.rut}`;
        return this.requestHandler.doGet<any>(url);
    }

    getStatusPassword(data: ClientBodyModel): Promise<any> {
        const url = `${environment.ENDPOINTS.BFF.BFF_CUSTOMERS_ASESORES}/client-data/estado-clave-cliente/${data.rut}`;
        return this.requestHandler.doGet<any>(url);
    }

    async getBalances(data: ClientBodyModel): Promise<ClientBalanceModel[]> {
        const parametros:any = await this.obtenerParametros();
        const reinicioProductosAsesor = parametros?.from?.toLowerCase() !== 'wfi';
        const url = environment.ENDPOINTS.BFF.BFF_CLIENTBALANCES;
        const rut = data.rut;
        return this.requestHandler.doPost<ClientBalanceModel[]>(`${url}/GetBalances`, { reinicioProductosAsesor, rut });
    }

    getCompanyInfo(data: ClientBodyModel): Promise<any> {
        if (Number(data.rut.split('-')[0]) > 50000000) {
            const url = environment.ENDPOINTS.BFF.BFF_CUSTOMERS_ASESORES;
            return this.requestHandler.doGet(`${url}/client-data/company-info/${data.rut}`);
        }
    }

    getSetUltimaSesionCliente(metodo: string, rut: string, tipoSesion: string, id?: string) {
        const url = `${environment.ENDPOINTS.ULTIMA_SESION}${metodo}`;
        const body = { rut, id, tipoSesion };
        return this.requestHandler.doPost<any[]>(url, body);
    }

    getExecutiveClient(data: ClientBodyModel): Promise<ClientExecutiveModel> {
        const url = environment.ENDPOINTS.BFF.BFF_CUSTOMERS_ASESORES;
        return this.requestHandler.doGet<ClientExecutiveModel>(`${url}/client-data/executive-client/${data.rut}`);
    }

    getLastTransaction(data: GetLastTransactionModel): Promise<any> {
        const url = `${environment.ENDPOINTS.BFF.BFF_CLIENTTRANSACTIONS}/transactions/getTransactions`;
        return this.requestHandler.doPost<TransactionsModel>(url, data);
    }

    getAllEmails(rut: string, APV: boolean, signedMandate: boolean) {
        const url = environment.ENDPOINTS.BFF.BFF_CUSTOMERS_ASESORES;
        return this.requestHandler.doGet<ClientEmail[]>(`${url}/client-data/emails?rut=${rut}&APV=${APV}&signedMandate=${signedMandate}`);
    }

    getAllEmailsPersonaJuridica(data) {
        const url = environment.ENDPOINTS.BFF.BFF_CUSTOMERS_ASESORES;
        return this.requestHandler.doPost<ClientEmail[]>(`${url}/client-data/emails-juridico`, data);
    }

    postSendPassword(data: EnviarClaveCliente): Promise<ClaveSuraGenerada> {
        const url = environment.ENDPOINTS.BFF.BFF_CUSTOMERS_ASESORES;
        return this.requestHandler.doPost<ClaveSuraGenerada>(`${url}/client-data/enviar-clave`, data);
    }

    postSendPasswordJuridico(data: EnviarClaveClienteJuridico): Promise<ClaveSuraGenerada> {
        const url = environment.ENDPOINTS.BFF.BFF_CUSTOMERS_ASESORES;
        return this.requestHandler.doPost<ClaveSuraGenerada>(`${url}/client-data/enviar-clave-persona-juridica`, data);
    }

    postSendPasswordTransaccional(data: EnviarClaveTransaccionalCliente): Promise<ClaveSuraGenerada> {
        const url = environment.ENDPOINTS.BFF.BFF_CUSTOMERS_ASESORES;
        return this.requestHandler.doPost<ClaveSuraGenerada>(`${url}/client-data/enviar-clave-transaccional`, data);
    }

    postSendPasswordTransaccionalJuridico(data: any): Promise<ClaveSuraGenerada> {
        const url = environment.ENDPOINTS.BFF.BFF_CUSTOMERS_ASESORES;
        return this.requestHandler.doPost<ClaveSuraGenerada>(`${url}/client-data/enviar-clave-transaccional-persona-juridica`, data);
    }

    getInvestorProfileQuestions() {
        try {
            const url = `${environment.ENDPOINTS.BFF.BFF_CLIENTUSERS}/preguntas-simulacion-pi`;
            return this.requestHandler.doGet<QuestionsProfile>(url);
        } catch (error) {
            return error;
        }
    }

    getPerfilInvestor(rut: string) {
        const url = `${environment.ENDPOINTS.BFF.BFF_CLIENTUSERS}/perfil-inversionista?rut=${rut}`;
        return this.requestHandler.doGet<PerfilInvestor>(url);
    }

    postObtenerPerfilSimulacion(data: ResponseQuestionsProfile) {
        try {
            const url = `${environment.ENDPOINTS.BFF.BFF_CLIENTUSERS}/obtener-perfil-simulacion`;
            return this.requestHandler.doPost<ResponseQuestionsProfile>(url, data);
        } catch (error) {
            return error;
        }
    }

    postObtenerPerfil(data: object) {
        try {
            const url = `${environment.ENDPOINTS.BFF.BFF_CLIENTUSERS}/simulacion-obtener-perfil`;
            return this.requestHandler.doPost<any>(url, data);
        } catch (error) {
            return error;
        }
    }

    postObtenerEmails(data: object) {
        try {
            const url = `${environment.ENDPOINTS.BFF.BFF_NOTIFICATIONS}/enviarCorreoCPI`;
            return this.requestHandler.doPost<any>(url, data);
        } catch (error) {
            return error;
        }
    }

    getSummaryOfTheClientProfile(data: ProfileSummaryParams): Promise<ProfileSummaryData> {
        try {
            const url = `${environment.ENDPOINTS.BFF.BFF_CUSTOMERS_ASESORES}/client-data/resumen-perfil-cliente?rut=${data.rut}&type=${data.type}`;
            return this.requestHandler.doGet<ProfileSummaryData>(url);
        } catch (error) {
            return error;
        }
    }

    getAddressDomainData(lob?: string): Promise<AddressDomain[]> {
        try {
            let url = `${environment.ENDPOINTS.BFF.BFF_CUSTOMERS_ASESORES}/client-data/datos-dominio-direccion`;
            if (lob) url = `${url}?lob=${lob}`
            return this.requestHandler.doGet<AddressDomain[]>(url);
        } catch (error) {
            return error;
        }
    }

    getAddressDomain(tipo, tipoClasificador): Promise<AddressDomain[]> {
        try {
            const url = `${environment.ENDPOINTS.BFF.BFF_CUSTOMERS_ASESORES}/client-data/datos-dominio?tipo=${tipo}&tipoClasificador=${tipoClasificador}`;
            return this.requestHandler.doGet<AddressDomain[]>(url);
        } catch (error) {
            return error;
        }
    }

    getRutero(id: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const identificador = "rutero-" + id;
            try {
                if (sessionStorage.getItem(identificador)) {
                    const rutero = sessionStorage.getItem(identificador);
                    return resolve(JSON.parse(rutero));
                }
            } catch (err) {
                console.log("No se tomó el rutero del SessionStorage, se sigue flujo normal.");
            }

            try {
                const data = {
                    filter: [
                        {
                            parametro: 'idRutero',
                            condicion: '==',
                            valor: id,
                        },
                    ],
                };
                const url = `${environment.ENDPOINTS.BFF.BFF_CLIENTCONFIG}/firestore/getRutero`;
                this.requestHandler.doPost<any>(url, data).then(response => {
                    sessionStorage.setItem(identificador, JSON.stringify(response));
                    resolve(response);
                }).catch(err => {
                    reject(err);
                });
            } catch (error) {
                reject(error);
            }
        });

    }

    postUpdateContactabilityData(data: UpdateContactabilityDTO): Promise<UpdateContactabilityResponse> {
        try {
            const url = `${environment.ENDPOINTS.BFF.BFF_CUSTOMERS_ASESORES}/client-data/actualizar-contactabilidad`;
            return this.requestHandler.doPost<UpdateContactabilityResponse>(url, data);
        } catch (error) {
            return error;
        }
    }
    async logData(log: Partial<InputLog> & Pick<InputLog, "Categoria" | "Metodo" | "Operacion" | "status">, indexLogStash: ConfigIndexLogStash) {
        try {
            const { EntradaLog, ...logData } = log;

            let inputLog: InputLog = new InputLog();
            inputLog.DetalleAccionExtra = {};
            inputLog.Categoria = log.Categoria;
            inputLog.Operacion = log.Operacion;
            inputLog.EntradaLog = {
                rut: JSON.parse(sessionStorage.getItem('dataContentful')).executiveRut,
                fechaHoraOp: moment(new Date()).format("DD/MM/YYYY HH:mm:ss"),
                ...EntradaLog,
            };
            inputLog.Salida = "-";
            inputLog.Secuencia = 0;
            inputLog.Servicio = "";
            inputLog.Tipo_sesion = "ASESOR";
            inputLog.extra = {};

            inputLog = {
                ...inputLog,
                ...logData,
            };
            await this.tracesService.registerLog(inputLog, "NWSA_ASESOR", "", "", "", indexLogStash);
        } catch (error) {
            console.error("error: ", error);
        }
    }
    async getLastSession(rut: string) {
        const resp: LastSessionModel = { rut: rut, lastLogin: sessionStorage.getItem('last_session') };
        await this.getUltimaSesionCliente(rut);
        return resp;
    }

    getCircularizacion(rut: string): Promise<any> {
        try {
            const url = `${environment.ENDPOINTS.BFF.BFF_ASESORES}/circularizacion/${rut}`;
            return this.requestHandler.doGet<any>(url);
        } catch (error) {
            return error;
        }
    }

    getProductDetail(data: any): Promise<any> {
        const url = `${environment.ENDPOINTS.BFF.BFF_CLIENTBALANCES}/GetDetails`;
        return this.requestHandler.doPost<any>(url, { ...data });
    }

    getDocumentoSaldosPorFallecimiento(data: any): Promise<any> {
        const url = `${environment.ENDPOINTS.BFF.BFF_CLIENTBALANCES}/getDocumentoSaldosPorFallecimiento`;
        return this.requestHandler.doPost<any>(url, { ...data });
    }

    getCertificateMonth(rut: string, fecha: string, lineaNegocio: BusinessLineType): Promise<any> {
        const businessLine = lineaNegocio === 'cb' ? 'cb' : 'vida';
        const url = `${environment.ENDPOINTS.BFF.BFF_CUSTOMERS_ASESORES}/balances/monthly-certificate?rut=${rut}&fecha=${fecha}&lineaNegocio=${businessLine}`;
        return this.requestHandler.doGet<any>(url);
    }

    getLastContactabilityUpdate(rut: string, businessLine: BusinessLine): Promise<LastContactabilityUpdateResponse> {
        const url = `${environment.ENDPOINTS.BFF.BFF_CUSTOMERS_ASESORES}/client-data/ultima-actualizacion-mis-datos?rutCliente=${rut}&lineaNegocio=${businessLine}&operacionId=${OperationId.CONTACTABILITY_UPDATE}`;
        return this.requestHandler.doGet<any>(url);
    }

    private async completarDatosCliente(rut, datosGuc) {
        let datosCliente = datosGuc;
        const productosCBporDefecto = [{
            "CodigoProducto": 434,
            "CodigoLinea": 3,
            "DescripcionProducto": "Acciones, ETFs y Fondos de Inversión",
            "DescripcionLinea": "CB",
            "CategoriaProducto": "ACCIONES",
            "DireccionProducto": "",
            "CelularProducto": "",
            "MailProducto": ""
        }];

        const productosGuc = datosCliente.DatosProductoGeneral?.DatosProductoBEL || [];
        const productosCB = productosGuc.filter((producto) => producto.CodigoLinea === 3);
        if (productosCB.length === 0) {
            const dataCliente = await this.clientFilterDataService.getDatosCliente(datosCliente);
            const accionInversion = await this.getAccionInversion(rut, dataCliente.nombre, dataCliente.apellido);
            sessionStorage.setItem("accion-inversion", JSON.stringify(accionInversion));

            const tieneFicha = accionInversion?.poseeFichacliente?.tieneFicha;
            if (tieneFicha) {
                const productosLimpios = productosGuc.filter((producto) => producto.CodigoLinea !== 0);
                datosCliente.DatosProductoGeneral.DatosProductoBEL = [...productosLimpios, ...productosCBporDefecto];

                const ficha = accionInversion?.poseeFichacliente?.dataOptimus?.SocioNegocio[0];
                const rut = ficha?.DocumentoIdentidad.split("-");
                const nombreClienteFicha = `${ficha?.Nombres} ${ficha?.ApellidoPaterno} ${ficha?.ApellidoMaterno}`;

                datosCliente.RutCliente = (datosGuc?.RutCliente === "") ? rut[0] : datosGuc?.RutCliente;
                datosCliente.DigitoVerificador = (datosGuc?.DigitoVerificador === "") ? rut[1] : datosGuc?.DigitoVerificador;
                datosCliente.NombreCliente = (datosGuc?.NombreCliente === "") ? nombreClienteFicha : datosGuc?.NombreCliente;
                datosCliente.EstadoCliente = (datosGuc?.EstadoCliente === "") ? "V" : datosGuc?.EstadoCliente;
                datosCliente.CodigoSegmento = (datosGuc?.CodigoSegmento === 0) ? 1 : datosGuc?.CodigoSegmento;
                datosCliente.TipoPersona = this.tipoPersona(ficha);
                sessionStorage.setItem('tipoPersona', datosCliente.TipoPersona);
            }
        }
        return datosCliente;
    }

    tipoPersona(perfil) {
        const tipoPersona = perfil?.TipoPersona?.toUpperCase();
        return tipoPersona.includes("NATURAL") ? "NATURAL" : "JURIDICA";
    }

    obtenerParametros(){
        return new Promise( resolve => {
            this.activateRoute.queryParams.subscribe( param => {
                resolve(param);
            });
        });
    }

}

export enum BusinessLine {
    RRVV = 0,
    FFMM = 1,
    VIDA = 2
}

export enum OperationId {
    CONTACTABILITY_UPDATE = 5
}

const lineTypes = ['vida', 'cb'] as const;
export type BusinessLineType = typeof lineTypes[number];
